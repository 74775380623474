@use 'styles/base/mixins';
@use 'styles/base/variables';

.consentContainer {
  width: 100%;
  height: 100%;
  background-color: variables.$gb_grey_800;
  color: variables.$gb_white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: variables.$spacing-m variables.$spacing-s;

  .text {
    margin-bottom: 1.375rem;

    p {
      color: variables.$gb_white;
      margin-bottom: 0;
    }

    & a {
      color: variables.$gb_white;
      display: inline;
      text-decoration: underline;
      padding: 0;
      white-space: nowrap;

      & > span:first-child {
        padding-right: 0;
      }

      &::before {
        content: none;
      }
    }

    span {
      color: variables.$gb_white;
    }
  }
}
