@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.consentContainer {
  width: 100%;
  height: 100%;
  background-color: $gb_grey_800;
  color: $gb_white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: $spacing-m $spacing-s;

  .text {
    margin-bottom: 1.375rem;

    p {
      color: $gb_white;
      margin-bottom: 0;
    }

    & a {
      color: $gb_white;
      display: inline;
      text-decoration: underline;
      padding: 0;
      white-space: nowrap;

      & > span:first-child {
        padding-right: 0;
      }

      &::before {
        content: none;
      }
    }

    span {
      color: $gb_white;
    }
  }
}
