@use 'styles/base/mixins';
@use 'styles/base/variables';

.locatorTile {
  strong {
    display: block;
    margin-bottom: 0.9375rem;
    text-transform: uppercase;
    font-size: 0.9375rem;
    line-height: 1.6;
    font-weight: variables.$font-weight-medium;
  }

  h3 {
    margin-bottom: 1.875rem;
  }

  p {
    margin-bottom: 2.25rem;
    font-size: 0.9375rem;
    line-height: 1.46666666667;
  }

  form {
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;

    input[type='text']:not([name='zipOrCity']) {
      border: 0;
      padding: 0.75rem 0.5rem;
      font-weight: variables.$font-weight-bold;
      background-color: transparent;
      @include mixins.line-clamp(1);
      max-width: calc(100% - 2.5rem);
      text-overflow: ellipsis;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    input[type='text']:not([name='zipOrCity'])::placeholder {
      opacity: 1;
      font-weight: variables.$font-weight-bold;
    }

    button {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%);

      i {
        font-size: 1.25rem;
      }
    }
  }

  &.locatorOne {
    .withOverflow {
      overflow: visible;
    }

    form {
      > div {
        span {
          position: absolute;
        }
      }

      & ul {
        z-index: 2;
        padding: 0.75rem 0 0.5rem 1rem;
        margin-top: 2.75rem;

        li {
          p {
            display: block;
          }

          p,
          span {
            color: variables.$gb_black;
          }
        }
      }
    }

    :global(.notification) {
      position: absolute;
      top: 1.5rem;
      left: 1rem;
      right: 1rem;
      width: unset;
      animation: none;
      z-index: 1;
    }
  }
}
